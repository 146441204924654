import {
  HowToUseStepItem,
  Trans,
  Typography,
} from '@basaldev/blocks-frontend-framework';

import logoUrl from '../../image/geekle.svg';
import merit2 from '../../image/merit-image-2.png';
import merit3 from '../../image/merit-image-3.png';
import merit1 from '../../image/merit-image-4.png';

type TFunction = NonNullable<Parameters<typeof Trans>[0]['t']>;

export function getByline(t: TFunction) {
  return (
    <Trans t={t} i18nKey="Geekle:landingByline">
      <>
        <img
          src={logoUrl}
          alt="Geekle"
          height={40}
          style={{ marginRight: '10px' }}
        />
        lets you find the best talent
      </>
    </Trans>
  );
}

export function getMeritItems(t: TFunction) {
  return [
    {
      imageUrl: merit1,
      subText: t('Geekle:landingMerits1SubText'),
      text: (
        <Trans t={t} i18nKey="Geekle:landingMerits1Text">
          A simple
          <Typography color="brand" size="inherit" weight="inherit">
            Monthly subscription
          </Typography>
          exclusive to Geekle
        </Trans>
      ),
    },
    {
      imageUrl: merit2,
      subText: t('Geekle:landingMerits2SubText'),
      text: (
        <Trans t={t} i18nKey="Geekle:landingMerits2Text">
          Manage offers on the
          <Typography color="brand" size="inherit" weight="inherit">
            project level
          </Typography>
        </Trans>
      ),
    },
    {
      imageUrl: merit3,
      subText: t('Geekle:landingMerits3SubText'),
      text: (
        <Trans t={t} i18nKey="Geekle:landingMerits3Text">
          <Typography color="brand" size="inherit" weight="inherit">
            Chat
          </Typography>
          to negotiate job details
        </Trans>
      ),
    },
  ];
}

export function getHowToUseStepItems(t: TFunction): HowToUseStepItem[] {
  return [
    {
      icon: 'edit',
      subText: t('Geekle:landingHowToUseStep1SubText'),
      text: t('Geekle:landingHowToUseStep1Text'),
    },
    {
      icon: 'account_box',
      subText: t('Geekle:landingHowToUseStep2SubText'),
      text: t('Geekle:landingHowToUseStep2Text'),
    },
    {
      icon: 'add_to_queue',
      subText: t('Geekle:landingHowToUseStep3SubText'),
      text: t('Geekle:landingHowToUseStep3Text'),
    },
  ];
}

export function getFAQItems(t: TFunction) {
  return [
    {
      answer: t('Geekle:landingFaqItem1Answer'),
      question: t('Geekle:landingFaqItem1Question'),
    },
    {
      answer: t('Geekle:landingFaqItem2Answer'),
      question: t('Geekle:landingFaqItem2Question'),
    },
    {
      answer: t('Geekle:landingFaqItem3Answer'),
      question: t('Geekle:landingFaqItem3Question'),
    },
  ];
}
