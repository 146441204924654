import {
  createApp,
  createAppInitializer,
} from '@basaldev/blocks-frontend-framework';
import { api, bucket, session, socket } from '@basaldev/blocks-frontend-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '@basaldev/blocks-frontend-framework/dist/style.css';
import './core-styles.css';
import { PaymentApi } from './api/PaymentApi';
import { SentryLogger } from './sentryLogger';
import { GeekleSupplyAppTemplate } from './template';

const domain = location.host.replace(/^.+?\./, '');

const sessionService = new session.CookieSessionService();
const authApi = new api.AuthDefaultAdapterApi(
  '/api/supply-auth',
  sessionService
);
const catalogApi = new api.CatalogDefaultAdapterApi(
  '/api/catalog',
  sessionService
);
const orderApi = new api.OrderDefaultAdapterApi('/api/order', sessionService);
const organizationApi = new api.OrganizationDefaultAdapterApi(
  '/api/organization',
  sessionService
);
const userApi = new api.UserDefaultAdapterApi(
  '/api/supply-user',
  sessionService
);

// PaymentApi is a custom route on the organization service
const paymentApi = new PaymentApi('/api/organization', sessionService);

const fileUploader = new bucket.GCPFileUploader();

const demandSiteProductUrl = `https://geekle.${domain}/positions/:productId`;

const demandSiteOrganizationUrl = `https://geekle.${domain}/organization/:organizationId`;

const chatApi = new api.MessagingChatAdapterApi<{ order_id: string }>(
  '/api/chat',
  sessionService
);
const chatSocketApi = new socket.MessagingChatSocketApi(
  '/api/chat',
  sessionService,
  {
    path: '/api/chat/socket.io',
    transports: ['polling'],
  }
);

const template = new GeekleSupplyAppTemplate(
  {
    appName: 'Geekle Frontend Supply',
    logger: new SentryLogger({
      appName: 'Geekle Frontend Supply',
      env: 'production',
    }),
    pageTitleConfiguration: {
      appName: 'Geekle',
    },
  },
  {
    authApi,
    catalogApi,
    chatApi,
    chatSocketApi,
    demandSiteOrganizationUrl,
    demandSiteProductUrl,
    fileUploader,
    orderApi,
    organizationApi,
    paymentApi,
    sessionService,
    userApi,
  }
);

const appInitializer = createAppInitializer({ template });
const App = createApp({ appInitializer, template });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
