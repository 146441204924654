/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  createAcceptInvitation,
  createChangeEmail,
  createChangeName,
  createChangePassword,
  createChangePhoneNumber,
  createCreateProductForTenant,
  createDummy,
  createLandingFAQ,
  createLandingHero,
  createLandingHowToUse,
  createLandingMerits,
  createListMessagesAndTopicsForTenantOrganizationBlock,
  createListSettingsPage,
  createLogin,
  createLogout,
  createMenuItemDefault,
  createMenuItemUserInfo,
  createMyOrganizationPage,
  createNavigation,
  createOrdersListForTenantOrganization,
  createOrganizationApplicationComplete,
  createOrganizationApplicationCreate,
  createOrganizationRegistration,
  createOrganizationUpdate,
  createProductsListForTenantOrganization,
  createRedirect,
  createResetPasswordRequest,
  createResetPasswordSubmit,
  createResetPasswordSuccess,
  createSections,
  createShowOrderDetailPage,
  createShowProductDetailPage,
  createSideNavigationItemDefault,
  createSideNavigationItemTenantOrganizationInfo,
  createUpdateProductForTenant,
  createVerifyChangeEmailSuccess,
  defaultApiErrorHandler,
  defaultErrorMessageI18nHandler,
  Error500,
  initializeAutoRefreshToken,
  initializeServiceVersionCheckers,
  isLoggedIn,
  isNotLoggedIn,
  isTenantOrganizationRegistered,
  Link,
  loadDefaultTranslations,
  LoadingPage,
  loadTranslationsFromYaml,
  NavigationHeaderElement,
  NotFound404,
  selectTenantFromOrganization,
  Template,
  TemplateOpts,
  ToastsDisplay,
} from '@basaldev/blocks-frontend-framework';
import {
  api,
  bucket,
  log,
  session,
  socket,
  utils,
} from '@basaldev/blocks-frontend-sdk';
import merge from 'lodash.merge';
import partial from 'lodash.partial';

import { PaymentApi } from './api/PaymentApi';
import {
  getByline,
  getFAQItems,
  getHowToUseStepItems,
  getMeritItems,
} from './block/LandingPage/landingPage';
import { createDashboardLink } from './block/RequiresPayment/DashboardLink';
import { createPaymentPage } from './block/RequiresPayment/PaymentPage';
import logoUrl from './image/geekle.svg';
import heroImageUrl from './image/hero-image.png';
import translationOverrides from './translationOverrides.yaml';
import { requiresPayment } from './validators/requiresPayment/requiresPayment.validator';

interface GeekleSupplyAppTemplateDependencies {
  /** Auth api client */
  authApi: Pick<
    api.AuthApi,
    | 'getOAuthGoogleLoginUrl'
    | 'login'
    | 'refresh'
    | 'logout'
    | 'onetimeTokenLogin'
    | 'getRequiredVersion'
    | 'ping'
  >;
  /** Catalog api client */
  catalogApi: Pick<
    api.CatalogApi,
    | 'createProduct'
    | 'createAttachment'
    | 'deleteAttachment'
    | 'getAttachmentUploadUrl'
    | 'getProduct'
    | 'listAttributes'
    | 'listCategories'
    | 'listProductsForOrganization'
    | 'getRequiredVersion'
    | 'updateProduct'
    | 'ping'
  >;
  /** Chat api client */
  chatApi: Pick<
    api.ChatApi<{ order_id: string }>,
    | 'createMessage'
    | 'createSubscription'
    | 'createTopic'
    | 'getMessage'
    | 'getRequiredVersion'
    | 'getTopic'
    | 'listMessagesForTopic'
    | 'listTopicsSubscribedByOrganization'
    | 'ping'
    | 'updateMessageReadStatus'
  >;
  /** Websocket api client */
  chatSocketApi: Pick<
    socket.ChatSocketApi,
    'subscribeToOrganizationId' | 'unsubscribeToOrganizationId'
  >;
  /** URl for demand site organization */
  demandSiteOrganizationUrl: string;
  /** URl for demand site product */
  demandSiteProductUrl: string;
  /** File uploader client */
  fileUploader: Pick<bucket.FileUploader, 'uploadFile'>;
  /** Order api client */
  orderApi: Pick<
    api.OrderApi,
    | 'getOrder'
    | 'listOrders'
    | 'listOrdersForOrganization'
    | 'getRequiredVersion'
    | 'updateOrder'
    | 'ping'
  >;
  /** Organization api client */
  organizationApi: Pick<
    api.OrganizationApi,
    | 'createOrganization'
    | 'getOrganization'
    | 'listOrganizationsForUser'
    | 'updateOrganization'
    | 'getRequiredVersion'
    | 'ping'
  >;
  paymentApi: Pick<PaymentApi, 'createDashboardLink'>;

  /** Session service for managing user sessions */
  sessionService: session.SessionService;
  /** User data api client */
  userApi: Pick<
    api.UserApi<unknown>,
    | 'acceptInvitation'
    | 'createUser'
    | 'updateUser'
    | 'getUser'
    | 'resetPassword'
    | 'sendResetPasswordEmail'
    | 'sendVerificationEmail'
    | 'verifyEmail'
    | 'getRequiredVersion'
    | 'ping'
    | 'acceptInvitation'
    | 'createInvitation'
    | 'deleteInvitation'
    | 'listInvitations'
    | 'changeEmail'
    | 'changePassword'
    | 'verifyChangeEmail'
    | 'listUsers'
  >;
}

export class GeekleSupplyAppTemplate implements Template {
  opts: Required<TemplateOpts>;
  dependencies: GeekleSupplyAppTemplateDependencies;

  constructor(
    opts: TemplateOpts,
    dependencies: GeekleSupplyAppTemplateDependencies
  ) {
    const domain = location.host.replace(/^.+?\./, '');
    this.opts = merge<Required<TemplateOpts>, TemplateOpts>(
      {
        apiErrorHandler: partial(defaultApiErrorHandler, dependencies.authApi),
        appInitialization: [
          partial(initializeAutoRefreshToken, dependencies.authApi, {
            refreshIntervalMs: 5 * 60 * 1000,
          }),
          partial(initializeServiceVersionCheckers, [
            dependencies.authApi,
            dependencies.userApi,
            dependencies.organizationApi,
            dependencies.catalogApi,
            dependencies.orderApi,
            dependencies.chatApi,
          ]),
        ],
        appInitializationLoader: LoadingPage,
        appName: 'geekle-supply-app',
        blockPages: [
          {
            component: createRedirect({
              options: { replace: true },
              to: 'landing.index',
            }),
            name: 'top',
            path: '/',
          },
          {
            component: createSections({
              components: [
                createLandingHero({
                  byline: getByline,
                  imageUrl: heroImageUrl,
                  signupRoute: 'organization.signup',
                }),
                createLandingMerits({
                  meritItems: getMeritItems,
                  signupRoute: 'organization.signup',
                }),
                createLandingHowToUse({
                  signupRoute: 'organization.signup',
                  stepItems: getHowToUseStepItems,
                }),
                createLandingFAQ({
                  faqItems: getFAQItems,
                  signupRoute: 'organization.signup',
                }),
              ],
            }),
            name: 'landing.index',
            navigationOptions: {
              hideSideNavigation: true,
              hideTopBarBorder: true,
            },
            pageTitle: (t) => t('Landing:pageTitle'),
            path: '/home',
            validators: {
              isNotLoggedIn: isNotLoggedIn({
                loggedInRedirect: 'jobs.index',
              }),
            },
          },
          {
            component: createOrganizationApplicationCreate({
              organizationApi: dependencies.organizationApi,
              privacyPolicyUrl: `https://geekle.${domain}/privacy-policy`,
              successRoute: 'organization.signup_complete',
              userAgreementUrl: `https://geekle.${domain}/user-agreement`,
            }),
            name: 'organization.signup',
            navigationOptions: {
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Organization:applicationCreatePageTitle'),
            path: '/organization/sign-up',
          },
          {
            component: createOrganizationApplicationComplete({
              returnToTopRoute: 'landing.index',
            }),
            name: 'organization.signup_complete',
            navigationOptions: {
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Organization:applicationCompletePageTitle'),
            path: '/organization/sign-up-complete',
          },
          {
            component: createLogin({
              alreadyVerifiedRoute: 'jobs.index',
              authApi: dependencies.authApi,
              showSignupLink: false,
              userApi: dependencies.userApi,
            }),
            name: 'auth.login',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:loginPageTitle'),
            path: '/auth/login',
          },
          {
            component: createResetPasswordRequest({
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-request',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordRequestPageTitle'),
            parentBlockPath: 'auth.login',
            path: '/auth/reset-password-request',
          },
          {
            component: createResetPasswordSubmit({
              passwordValidateStrategy: utils.defaultPasswordValidateStrategy,
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-submit',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordSubmitPageTitle'),
            path: '/auth/reset-password-submit/:resetPasswordToken',
          },
          {
            component: createResetPasswordSuccess({
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-success',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordSuccessPageTitle'),
            path: '/auth/reset-password-success',
          },
          {
            component: createLogout({
              authApi: dependencies.authApi,
              logoutRedirect: '/auth/login',
            }),
            name: 'auth.logout',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            path: '/auth/logout',
          },
          {
            component: createAcceptInvitation({
              passwordValidateStrategy: utils.defaultPasswordValidateStrategy,
              redirectAfterAcceptRoute: 'auth.login',
              userApi: dependencies.userApi,
              userTypeId: '010',
            }),
            name: 'auth.accept-invitation',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:acceptInvitationPageTitle'),
            path: '/auth/accept-invitation/:invitationId/:token',
            validators: {
              isNotLoggedIn: isNotLoggedIn({
                loggedInRedirect: 'jobs.index',
              }),
            },
          },
          {
            component: createProductsListForTenantOrganization({
              catalogApi: dependencies.catalogApi,
              createProductRoute: 'jobs.create',
              showProductRoute: 'jobs.show',
              updateProductRoute: 'jobs.update',
            }),
            name: 'jobs.index',
            pageTitle: (t) => t('Geekle:jobsTitle'),
            path: '/jobs',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
              needsPaymentSetup: requiresPayment({
                notValidRedirect: 'payment.index',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createShowProductDetailPage({
              catalogApi: dependencies.catalogApi,
              editProductRoute: 'jobs.update',
              demandSiteProductUrl: dependencies.demandSiteProductUrl,
            }),
            name: 'jobs.show',
            pageTitle: {
              fallback: () => '',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              fetch: async (blockProps, _t, updatePageTitle) => {
                const productId = blockProps.params.productId;
                if (!productId) {
                  return;
                }
                const product = await dependencies.catalogApi.getProduct({
                  productId,
                });
                if (product && product.name) {
                  updatePageTitle(product.name);
                }
              },
            },
            path: '/jobs/:productId',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            parentBlockPath: 'jobs.index',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
            },
          },
          {
            component: createCreateProductForTenant({
              attributeName: 'skills',
              catalogApi: dependencies.catalogApi,
              fileUploader: dependencies.fileUploader,
              successRoute: 'jobs.show',
            }),
            name: 'jobs.create',
            navigationOptions: {
              topBarType: 'singleItem',
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Geekle:jobsCreateTitle'),
            path: '/jobs/create',
            parentBlockPath: 'jobs.index',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
              needsPaymentSetup: requiresPayment({
                notValidRedirect: 'payment.index',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createUpdateProductForTenant({
              attributeName: 'skills',
              catalogApi: dependencies.catalogApi,
              fileUploader: dependencies.fileUploader,
              successRoute: 'jobs.show',
            }),
            name: 'jobs.update',
            navigationOptions: {
              topBarType: 'singleItem',
              hideSideNavigation: true,
            },
            pageTitle: {
              fallback: () => '',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              fetch: async (blockProps, _t, updatePageTitle) => {
                const productId = blockProps.params.productId;
                if (!productId) {
                  return;
                }
                const product = await dependencies.catalogApi.getProduct({
                  productId,
                });
                if (product && product.name) {
                  updatePageTitle(product.name);
                }
              },
            },
            path: '/jobs/:productId/update',
            parentBlockPath: 'jobs.show',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
              needsPaymentSetup: requiresPayment({
                notValidRedirect: 'payment.index',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createOrdersListForTenantOrganization({
              chatRoute: 'messages.index',
              chatApi: dependencies.chatApi,
              orderApi: dependencies.orderApi,
              showOrderRoute: 'order.show',
            }),
            name: 'orders.index',
            pageTitle: (t) => t('Geekle:ordersTitle'),
            path: '/job-applications',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
              needsPaymentSetup: requiresPayment({
                notValidRedirect: 'payment.index',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createShowOrderDetailPage({
              chatApi: dependencies.chatApi,
              chatRoute: 'messages.index',
              catalogApi: dependencies.catalogApi,
              orderApi: dependencies.orderApi,
              productShowPageRoute: 'jobs.show',
            }),
            name: 'order.show',
            parentBlockPath: 'orders.index',
            pageTitle: {
              fallback: () => '',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              fetch: async (blockProps, _t, updatePageTitle) => {
                const { orderId } = blockProps.params;
                const { tenantId } = blockProps.sessionState;
                if (!orderId || !tenantId) {
                  return;
                }

                const order = await dependencies.orderApi.getOrder({
                  orgId: tenantId,
                  orderId,
                });

                if (order && order.customer) {
                  updatePageTitle(order.customer?.name);
                }
              },
            },
            navigationOptions: {
              topBarType: 'singleItem',
              hideSideNavigation: true,
            },
            path: '/job-applications/:orderId',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
              needsPaymentSetup: requiresPayment({
                notValidRedirect: 'payment.index',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createListMessagesAndTopicsForTenantOrganizationBlock({
              chatApi: dependencies.chatApi,
              organizationApi: dependencies.organizationApi,
              orderShowRoute: 'order.show',
              organizationShowRoute: 'organization.show',
              socketApi: dependencies.chatSocketApi,
              topicShowRoute: 'messages.index',
              userApi: dependencies.userApi,
            }),
            name: 'messages.index',
            pageTitle: (t) =>
              t(
                'Chat:listMessagesAndTopicsForTenantOrganizationBlock.pageTitle'
              ),
            path: '/messages',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
              needsPaymentSetup: requiresPayment({
                notValidRedirect: 'payment.index',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createMyOrganizationPage({
              organizationApi: dependencies.organizationApi,
              demandSiteOrganizationUrl: dependencies.demandSiteOrganizationUrl,
              editCompanyRoute: 'organization.edit',
            }),
            name: 'organization.show',
            pageTitle: (t) => t('Geekle:myOrganizationTitle'),
            path: '/my-organization',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
            },
          },
          {
            component: createOrganizationUpdate({
              organizationApi: dependencies.organizationApi,
              successRoute: 'organization.show',
            }),
            name: 'organization.edit',
            pageTitle: (t) => t('Geekle:myOrganizationTitle'),
            path: '/organization/:orgId/edit',
            parentBlockPath: 'organization.show',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
            },
          },
          {
            component: createDummy({ text: 'Unavailable' }),
            // component: createDashboardLink({
            //   paymentApi: dependencies.paymentApi,
            // }),
            // navigationOptions: {
            //   hideSideNavigation: true,
            //   topBarType: 'noMenu',
            // },
            name: 'invoices.index',
            pageTitle: (t) => t('Geekle:invoicesTitle'),
            path: '/invoices',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
            },
          },
          {
            component: createDummy({ text: 'TODO: Members for tenant' }),
            name: 'members.index',
            pageTitle: (t) => t('Geekle:membersTitle'),
            path: '/members',
            validators: {
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
            },
          },
          {
            component: createOrganizationRegistration({
              organizationApi: dependencies.organizationApi,
              successRoute: 'jobs.index',
            }),
            name: 'organization.registration',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Geekle:organizationRegistrationTitle'),
            path: '/organization/registration',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
            },
          },
          {
            component: createListSettingsPage({
              changeEmailRoute: 'settings.change-email',
              changeNameRoute: 'settings.change-name',
              changePasswordRoute: 'settings.change-password',
              changePhoneNumberRoute: 'settings.change-phone-number',
              userApi: dependencies.userApi,
            }),
            name: 'settings',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Profile:ProfileMenuItemLabel'),
            parentBlockPath: 'jobs.index',
            path: '/settings',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangeEmail({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-email',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changeEmail.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-email',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangeName({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-name',
            navigationOptions: {
              topBarType: 'singleItem',
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Settings:changeName.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-name',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangePhoneNumber({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-phone-number',
            navigationOptions: {
              topBarType: 'singleItem',
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Settings:changePhoneNumber.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-phone-number',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createVerifyChangeEmailSuccess({
              userApi: dependencies.userApi,
              verifiedRoute: 'settings',
            }),
            name: 'settings.verify-change-email-success',
            navigationOptions: {
              topBarType: 'noMenu',
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Settings:verifyEmail.title'),
            path: '/settings/verify-change-email-success/:verifyChangeEmailToken',
          },
          {
            component: createDashboardLink({
              paymentApi: dependencies.paymentApi,
            }),
            name: 'payment.dashboard',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Payment:title'),
            path: '/settings/payment',
          },
          {
            component: createChangePassword({
              cancelRoute: 'settings',
              successRoute: 'settings',
              forgotPasswordUrl: 'auth.reset-password-request',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-password',
            navigationOptions: {
              topBarType: 'singleItem',
              hideSideNavigation: true,
            },
            pageTitle: (t) => t('Settings:changePassword.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-password',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createPaymentPage({
              dashboardRoute: 'payment.dashboard',
            }),
            name: 'payment.index',
            pageTitle: (t) => t('Payment:title'),
            path: '/payment-required',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
              selectTenantFromOrganization: selectTenantFromOrganization({
                notLoggedInRedirect: 'auth.login',
                organizationApi: dependencies.organizationApi,
              }),
              isTenantOrganizationRegistered: isTenantOrganizationRegistered({
                organizationApi: dependencies.organizationApi,
                registrationNotCompleteRedirect: 'organization.registration',
              }),
            },
          },
        ],
        error500Component: Error500,
        errorMessageI18nHandler: defaultErrorMessageI18nHandler,
        i18nOptions: {
          resources: merge(
            loadDefaultTranslations(),
            loadTranslationsFromYaml(translationOverrides)
          ),
        },
        logger: new log.FrontendLogger({
          appName: opts.appName ?? 'geekle-supply-app',
          // @ts-expect-error vite defines magic property
          env: import.meta.env.PROD ? 'production' : 'development',
        }),
        navigationComponent: createNavigation({
          headerElements: (isLoggedIn: boolean): NavigationHeaderElement => {
            if (isLoggedIn) {
              return {
                icons: [],
                type: 'icons',
              };
            }
            return {
              buttons: [
                {
                  children: 'ログイン',
                  size: 'S',
                },
                {
                  children: '無料で利用申し込み',
                  fill: 'fill',
                  size: 'S',
                  textEmphasis: true,
                },
              ],
              links: [
                {
                  to: 'auth.login',
                },
                {
                  to: 'organization.signup',
                },
              ],
              type: 'buttons',
            };
          },
          headerLogoElement: (onNavigate) => (
            <Link href="/" onNavigate={onNavigate}>
              <img src={logoUrl} alt="Geekle logo" />
            </Link>
          ),
          menuNavigationBlocks: [
            createMenuItemUserInfo({
              userApi: dependencies.userApi,
            }),
            createMenuItemDefault({
              text: (t) => t('Profile:ProfileMenuItemLabel'),
              toRoute: 'settings',
            }),
            createMenuItemDefault({
              text: (t) => t('Auth:logoutButton'),
              toRoute: 'auth.logout',
            }),
          ],
          sideNavigationBlocks: [
            createSideNavigationItemTenantOrganizationInfo({
              organizationApi: dependencies.organizationApi,
            }),
            createSideNavigationItemDefault({
              icon: 'person',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarJobsLabelText'),
              toRoute: 'jobs.index',
            }),
            createSideNavigationItemDefault({
              icon: 'front_hand',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarOrdersLabelText'),
              toRoute: 'orders.index',
            }),
            createSideNavigationItemDefault({
              icon: 'mail',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarMessagesLabelText'),
              toRoute: 'messages.index',
            }),
            createSideNavigationItemDefault({
              icon: 'maps_home_work',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarMyOrganizationLabelText'),
              toRoute: 'organization.show',
            }),
            createSideNavigationItemDefault({
              icon: 'payments',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarInvoicesLabelText'),
              toRoute: 'invoices.index',
            }),
            createSideNavigationItemDefault({
              icon: 'person',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarMembersLabelText'),
              toRoute: 'members.index',
            }),
          ],
        }),
        notFound404Component: NotFound404,
        pageTitleConfiguration: {
          appName: 'Sample App',
        },
        screenConfiguration: {
          desktopCutoff: 950,
          enabledSizes: {
            bigDesktop: true,
            desktop: true,
            mobile: false,
          },
          mobileCutoff: 640,
        },
        theme: {
          configs: {
            timezone: 'Asia/Tokyo',
          },
        },
        toastConfiguration: {
          displayMs: 5000,
          toastComponent: ToastsDisplay,
        },
      },
      opts
    );
    this.dependencies = dependencies;
  }
}
