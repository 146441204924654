import {
  ErrorMessageI18nHandler,
  useApiMutation,
} from '@basaldev/blocks-frontend-framework';
import { useEffect } from 'react';

import { PaymentApi } from '../../api/PaymentApi';

export interface UseGetDashboardUrlApi {
  /** The error message handler */
  errorMessageI18nHandler: ErrorMessageI18nHandler;
  /** The function to navigate to a new route */
  onNavigate: (url: string) => void;
  /** The payment API */
  paymentApi: Pick<PaymentApi, 'createDashboardLink'>;
  /** Show a toast message */
  showToast: (message: string) => void;
  /** The user ID */
  userId: string | null;
}

/**
 * Hook that performs a request to a custom route and redirects the user to the verified the payment dashboard
 */
export const useGetDashboardUrl = ({
  errorMessageI18nHandler,
  onNavigate,
  showToast,
  paymentApi,
  userId,
}: UseGetDashboardUrlApi) => {
  const [createDashboardLink] = useApiMutation(
    paymentApi.createDashboardLink.bind(paymentApi),
    {
      errorMessage: errorMessageI18nHandler,
      showToast,
    }
  );

  useEffect(() => {
    const handleGetLink = async () => {
      if (!userId) {
        return;
      }
      const linkData = await createDashboardLink({
        userId,
      });
      if (linkData) {
        window.location.href = linkData.url;
      }
    };

    void handleGetLink();
  }, [createDashboardLink, onNavigate, userId]);
};
