import { api } from '@basaldev/blocks-frontend-sdk';

export interface CreateDashboardLinkResponse {
  /** The date the dashboard link was created */
  createdAt: string;
  /** The URL to redirect the user back to a page */
  returnUrl: string;
  /** The URL to redirect the user to the dashboard */
  url: string;
}
export interface CreateDashboardLinkInput {
  /** The user ID of the current logged in user to create the dashboard link for */
  userId: string;
}

/**
 * API for payment related operations
 */
export class PaymentApi extends api.OrganizationDefaultAdapterApi {
  /**
   * Create a dashboard link for the user
   */
  async createDashboardLink(
    input: CreateDashboardLinkInput
  ): Promise<CreateDashboardLinkResponse> {
    const url = this.getServiceUrl('/payment-dashboard');
    const response = await this.sessionService.performPost<
      CreateDashboardLinkResponse,
      CreateDashboardLinkInput
    >(url.toString(), input);
    return response;
  }
}
