import {
  BlockComponentProps,
  PaymentPage,
  useTranslation,
} from '@basaldev/blocks-frontend-framework';
import { NBError, ErrorCode } from '@basaldev/blocks-frontend-sdk';

export interface PaymentPageOptions {
  /** The route to redirect to call the dashboard url generation API */
  dashboardRoute: string;
}

/**
 * Creates a page that allows the user to setup payment details
 */
export const createPaymentPage = ({ dashboardRoute }: PaymentPageOptions) => {
  const ChangeEmailPage: React.FC<BlockComponentProps> = (blockProps) => {
    const { t } = useTranslation();
    const { sessionState, onNavigate, urlForRoute } = blockProps;
    if (!sessionState.userId) {
      throw new NBError({
        code: ErrorCode.internalServerError,
        message: 'User Id ID missing',
      });
    }

    return (
      <PaymentPage
        screenMode={blockProps.screenMode}
        pageTitle={t('Payment:title')}
        subtitle={t('Payment:subtitle')}
        bodyText={t('Payment:bodyText')}
        subscriptionCost={t('Payment:subscriptionCost')}
        subscriptionTrialLength={t('Payment:subscriptionTrialLength')}
        paymentButtonLabel={t('Payment:paymentButtonLabel')}
        paymentButtonHref={urlForRoute(dashboardRoute)}
        onNavigate={onNavigate}
        rowLabels={{
          paymentButton: t('Payment:rowLabels.paymentButton'),
          subscriptionCost: t('Payment:rowLabels.subscriptionCost'),
          subscriptionTrialLength: t(
            'Payment:rowLabels.subscriptionTrialLength'
          ),
        }}
      />
    );
  };

  return ChangeEmailPage;
};
