import {
  BlockComponentProps,
  LoadingPage,
} from '@basaldev/blocks-frontend-framework';

import { useGetDashboardUrl } from './useDashboardLinkApi';
import { PaymentApi } from '../../api/PaymentApi';

export interface DashboardLinkOptions {
  // The payment API
  paymentApi: Pick<PaymentApi, 'createDashboardLink'>;
}

/**
 * Creates a block that redirects the user to the dashboard
 */
export const createDashboardLink = ({ paymentApi }: DashboardLinkOptions) => {
  const DashboardLink: React.FC<BlockComponentProps> = ({
    errorMessageI18nHandler,
    onNavigate,
    sessionState,
    showToast,
    screenMode,
  }) => {
    useGetDashboardUrl({
      errorMessageI18nHandler,
      onNavigate,
      paymentApi,
      showToast,
      userId: sessionState?.userId ?? null,
    });

    return <LoadingPage screenMode={screenMode} />;
  };

  return DashboardLink;
};
