// Copyright 2024 Co-Lift Inc.

import {
  BlockValidator,
  BlockValidatorProps,
  LoadingPage,
  useApiGet,
} from '@basaldev/blocks-frontend-framework';
import { api, ErrorCode, NBError } from '@basaldev/blocks-frontend-sdk';
import { useEffect } from 'react';

export interface RequiresPayment {
  /** Path to redirect when not logged in */
  notValidRedirect: string;
  /** organization api */
  organizationApi: Pick<api.OrganizationApi, 'getOrganization'>;
}

type ExpectedCustomFields = {
  needs_payment_method_setup: boolean;
};

/**
 * Validates if user is need to setup payment details
 * Scenarios:
 * - User has not paid/setup payment -> navigate
 * - User has paid or trialing -> show children
 *
 * @param options
 * - notValidRedirect
 * - organizationApi
 * @returns
 */
export const requiresPayment: BlockValidator<RequiresPayment> = ({
  notValidRedirect,
  organizationApi,
}) => {
  const RequiresPaymentValidator: React.FC<BlockValidatorProps> = ({
    children,
    sessionState,
    onNavigate,
    urlForRoute,
  }) => {
    if (!sessionState.tenantId) {
      throw new NBError({
        code: ErrorCode.internalServerError,
        message: 'Organization ID not found',
      });
    }

    const [{ data, loading }] = useApiGet(
      organizationApi.getOrganization.bind(organizationApi),
      {
        orgId: sessionState.tenantId ?? '',
      }
    );
    useEffect(() => {
      if (data?.customFields) {
        const requiresPayment = (data?.customFields as ExpectedCustomFields)
          .needs_payment_method_setup;
        if (requiresPayment) {
          onNavigate(urlForRoute(notValidRedirect));
          return;
        }
      }
    }, [data, onNavigate, urlForRoute]);
    if (loading) {
      return <LoadingPage screenMode="desktop" />;
    }
    return <>{children}</>;
  };

  return RequiresPaymentValidator;
};
